export const CONFIG = {
  cognitoUserPoolId: 'us-west-2_ZyXLLyxBs',
  userPoolWebClientId: '563dd98v6jldbvur22mnj6kuqe',
  cognitoIdentityPoolId: 'us-west-2:b0ff62ee-a5b0-4890-814a-2e48b5f968b6',
  awsRegion: 'us-west-2',
  documentsUploadsBucket: 'contractor-documents-dev',
  userUploadsBucket: 'contractor-userdata-dev',

  baseApiUrl: 'https://www.api.contractor.eparconnectserver.com/dev-',
  apiStage: 'dev',
  trackingId:"UA-164108890-1",
}
