// @flow

import React, { Component } from 'react';
import { Layout, Input, Button, Checkbox, message, Progress } from 'antd';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Auth } from 'aws-amplify';

const { Content } = Layout;

class ForgotPasswordView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      isUsernameSearching: false,
      isPasswordResetEmailSent: false,
      isPasswordResetError: false,
      userId: ''
    };

    this.searchUsername = this.searchUsername.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  searchUsername(username: string) {
    if (username === '') {
      message.warn('Email cannot be empty');
      return;
    }

    this.setState({
      isUsernameSearching: true,
      isPasswordResetError: false
    });
    Auth.forgotPassword(username)
      .then(data => {
        console.log(data);
        message.success("We have emailed you instructions to reset password.");
        this.setState({
          isUsernameSearching: false,
          isPasswordResetEmailSent: true
        });
      })
      .catch((e) => {
        console.log(e);
        message.error(e.message);
        this.setState({
          isUsernameSearching: false,
          isPasswordResetEmailSent: false
        });
      });
  }

  renderIfUsernameSearching() {
    if (this.state.isUsernameSearching) {
      return (
        <div className='pure-g'>
          <div className='pure-u-1'>
            <Progress percent={100} showInfo={false} status="active" />
          </div>
        </div>
      )
    } else {
      return null;
    }
  }

  renderIfResetUnsuccessful() {
    if (this.state.isPasswordResetError) {
      return (
        <div className='pure-g epar__forgotpassword__contact-admin--container'>
          <div className='pure-u-1'>
            <span className='epar__forgotpassword__contact-admin'>
              We didn't find any account based on the info you provided. Please contact administrator.
            </span>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }

  renderSetNewPassword() {
    if (this.state.isPasswordResetEmailSent) {
      return (
        <div className='pure-g epar__forgotpassword__check-mail--container'>
          <div className='pure-u-1'>
            <span className='epar__forgotpassword__check-mail'>
              Please check your email to reset password
            </span>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  renderView() {
    return (

      <div>
        <div className='pure-g epar__login__form-header'>
          <div className='pure-u-1-1'>
            <div className='pure-g'>
              <div className='pure-u-1'>
                <img src='/assets/logo.png' className="epar__login__form--logo" alt='' />
              </div>
            </div>
            <div className='pure-g'>
              <div className='pure-u-1 epar__content--center'>
                <p>Reset Password</p>
              </div>
            </div>
          </div>
        </div>

        <div className='epar__login__forgot-password-form__content'>
          <div className='pure-g'>
            <div className='pure-u-1 epar__login__forgot-password-form__center_content'>
              <span>Enter your email below and we will send you an email with a code to reset your password</span>
              <Input placeholder="Enter email" onChange={this.handleChange} id="userId" name="userId" />
              <Button onClick={() => this.searchUsername(this.state.userId)}>Send Password Reset Code</Button>
            </div>
          </div>


          <div className='pure-g epar__login__forgot-password-form__have-code'>
            <div className='pure-u-1'>
              <Link to="reset_password"><Checkbox>I have recieved the code and ready to reset my password</Checkbox></Link>
            </div>
          </div>

          {this.renderIfUsernameSearching()}
          {this.renderSetNewPassword()}
          {this.renderIfResetUnsuccessful()}
        </div>
      </div>
    )
  }

  render() {

    return (
      <Layout style={{ minHeight: '100vh', padding: '20px' }}>
        <Content style={{ minHeight: '100vh' }}>
          <Row style={{ minHeight: '100vh' }} className='epar__login__container' type="flex" justify="space-around" align="middle">
            <Col sm={1} md={12} lg={12} xl={12}>
              <div className='epar__login__forgot-password-form__container'>
                {this.renderView()}
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    )
  }
}


export default ForgotPasswordView;
