// @flow

import React, { Component } from 'react';
import { Layout, Form, Icon, Input, Button, message, Progress, Row, Col } from 'antd';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const { Content } = Layout;
const FormItem = Form.Item;

// Define the properties used in this component
type LoginFormFields = {
  username: string,
  code: string,
  newPassword: string,
  confirmPassword: string
}

type FormType = {
    validateFields(callback: Function): void,
    getFieldDecorator(fields: any): Function,
    onSubmit(callback: Function): Function,
    getFieldValue(name: string): any
}

type ComponentProps = {
  message: string,
  form: FormType,
  resetPassword(string,string, string): Function
}


class ResetPasswordFormView extends Component<ComponentProps> {

  constructor(props: ComponentProps){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showMessages = this.showMessages.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.renderIfInProgress = this.renderIfInProgress.bind(this);

    this.state = {
      isPasswordReset: false,
      isInProgress: false
    };
  }

  resetPassword(username, code, password){
    // Collect confirmation code and new password, then
    this.setState({isInProgress: true});
    Auth.forgotPasswordSubmit(username, code, password)
      .then(data => {
        console.log(data);
        message.success("Password reset successfully.");
        this.setState({
          isPasswordReset: true,
          isInProgress: false
        });
      })
      .catch((e) => {
        console.log(e);
        message.error(e.message);
        this.setState({isInProgress: false});
      });
  }
  
  renderIfInProgress(){
    if (this.state.isInProgress){
      return (
        <div className='pure-g'>
          <div className='pure-u-1'>
            <Progress percent={100} showInfo={false} status="active" />
          </div>
        </div>
      )
    } else {
      return null;
    }
  }

  handleSubmit(e: SyntheticEvent<HTMLButtonElement>){
    e.preventDefault();
    this.props.form.validateFields((err: Error, values: LoginFormFields) => {
      if (!err) {
        this.resetPassword(values.username, values.code, values.newPassword);
      }
    });
  }

  showMessages(){
    if (this.props.message !== ''){
      const message = this.props.message;
        return ( <FormItem className='epar__validation-label'><p>{message}</p></FormItem> );
    }
    else {
      return ''
    }
  }

  redirectToLoginIfPasswordReset(){
    if (this.state.isPasswordReset){
      return <Redirect to="/login"/>;
    } 
    else {
      return null;
    }
  }

  render(){
    return (
      <div className='epar__reset-password-form'>
      <Layout style={{ minHeight: '100vh', padding: '20px' }}>
      <Content style={{ minHeight: '100vh' }}>
          <Row style={{ minHeight: '100vh' }} className='epar__login__container' type="flex" justify="space-around" align="middle">
            <Col sm={1} md={12} lg={12} xl={12}>
              <div className='epar__login__form__container'>
                { this.renderView() }
              </div>
            </Col>
          </Row>
      </Content>
    </Layout>
    </div>
    )
  }

  renderView = () => { 
    const { getFieldDecorator } = this.props.form;
    return (

      <div>

        {this.redirectToLoginIfPasswordReset()}

        <div className='pure-g epar__login__form-header'>
          <div className='pure-u-1-1'>
            <div className='pure-g'>
              <div className='pure-u-1'>
                <img src='/assets/logo.png' className="epar__login__form--logo" alt=''/>
              </div>
            </div>

            <div className='pure-g'>
              <div className='pure-u-1 epar__content--center'>
                <h4>Reset your password</h4>
              </div>
            </div>
          </div>

        </div>

          <Form onSubmit={this.handleSubmit} className="epar__login__form">
            {this.showMessages()}
            <FormItem label='Email' className='epar__login-form__field'>
              {getFieldDecorator('username', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your email!'
                  }
                ]
              })(
                <Input
                  prefix={< Icon type="user" style={{ fontSize: 13 }} />}
                  placeholder="Enter email" />
              )}
            </FormItem>

            <FormItem label={'Security Code'}>
              {getFieldDecorator('code', {
                rules: [{ required: true, message: 'Please input the code' }],
              })(
                <Input prefix={<Icon type="lock" style={{ fontSize: 13 }} />} 
                  placeholder={"Enter code you recieved via email"} value={this.props.password}/>
              )}
            </FormItem>

            <FormItem label={'New Password'}>
              {getFieldDecorator('newPassword', {
                rules: [{ required: true, message: 'Please input your Password!' }],
              })(
                <Input prefix={<Icon type="lock" style={{ fontSize: 13 }} />} type="password" 
                  placeholder={"Enter your new password"} />
              )}
              <div>
                <p>Please note: For security reasons, your password must;</p>
                <ul>
                  <li>be a minimum of 8 characters</li>
                  <li>contain at least one number</li>
                  <li>contain at least one lowercase letter</li>
                  <li>contain at least one capital letter</li>
                </ul>
              </div>
            </FormItem>

            <FormItem label={'Confirm New Password'}>
              {getFieldDecorator('confirmPassword', {
                rules: [
                  { required: true, message: 'Please confirm your Password!' },
                  { required: true, message: 'Password and confirm password do not match', validator: (rule, value, callback) => {
                    const { getFieldValue } = this.props.form
                    if (value && value !== getFieldValue('newPassword')){
                      callback(new Error('Passwords do not match'));
                    }

                    callback();
                  }}
                ],
              })(
                <Input prefix={<Icon type="lock" style={{ fontSize: 13 }} />} type="password" 
                  placeholder={"Enter your new password again"} />
              )}
            </FormItem>

            <FormItem className="epar__content--center">
              <Button type="primary" htmlType="submit" className="login-form-button">
                Reset My Password
              </Button>
            </FormItem>
          </Form>

          {this.renderIfInProgress()}
      </div>
    )
  }
}

const resetPasswordForm = Form.create()(ResetPasswordFormView);

export default resetPasswordForm;
