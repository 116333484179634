import React, { Component } from 'react';
import { Button, Icon, Card, Tag, Modal } from 'antd';
import { isMobile } from '../utils/common-utils';
import download from 'downloadjs';
import { PDFReader } from 'react-read-pdf';
import { openFile } from '../utils/file-utils';

class PDFViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1,
            isMaximized: false
        }
        this.minimize = this.minimize.bind(this);
    }

    onDocumentLoadSuccess = (numPages) => {
        this.setState({ numPages });
    }

    resetScrolTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    prev() {
        if (this.state.pageNumber > 1) {
            this.setState({ pageNumber: this.state.pageNumber - 1 });
            this.resetScrolTop();
        }
    }

    next() {
        if (this.state.pageNumber < this.state.numPages) {
            this.setState({ pageNumber: this.state.pageNumber + 1 });
            this.resetScrolTop();
        }
    }

    download() {
        if (this.props.docContent) {
            download(this.props.docContent, `${this.props.fileName}.pdf`, 'application/pdf');
        } else if (this.props.docContentUrl) {
            openFile(this.props.docContentUrl);
        }
    }

    maximize() {
        this.setState({ isMaximized: true });
    }

    minimize() {
        this.setState({ isMaximized: false });
    }

    renderMaximizeController() {
        if (!this.props.isPDFViewMaximized) {
            return <Button type="primary" style={{ marginLeft: '10px' }} shape="circle" icon="arrows-alt" size="small" onClick={() => { this.maximize() }} />
        }
    }

    renderControllers(pageNumber, numPages) {
        if (isMobile()) {
            return (
                <div className="pure-g">
                    <div className="pure-u-1-4"></div>
                    <div className="pure-u-1 pure-u-md-1-2 ">
                        <div className="pure-u-1-6" style={{ textAlign: 'center' }}>
                            <Tag closable={false}>{pageNumber} / {numPages}</Tag>
                        </div>
                        <div className="pure-u-2-3" style={{ textAlign: 'center' }}>
                            {this.renderMaximizeController()}
                            <Button type="primary" style={{ marginLeft: '10px' }} shape="circle" icon="download" size="small" onClick={() => { this.download() }} />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="pure-g">
                <div className="pure-u-1-3"></div>
                <div className="pure-u-1 pure-u-md-1-3 ">
                    <div className="pure-u-1-5" style={{ textAlign: 'center' }}>
                        <Tag closable={false}>{pageNumber} / {numPages}</Tag>
                    </div>
                    <div className="pure-u-3-5" style={{ textAlign: 'center' }}>
                        <Button type="primary" style={{ marginLeft: '10px' }} shape="circle" icon="download" size="small" onClick={() => { this.download() }} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { pageNumber, numPages } = this.state;
        return (
            <div>
                <Modal
                    title={this.props.fileName}
                    style={{ top: 5 }}
                    visible={!this.props.isPDFViewMaximized && this.state.isMaximized}
                    onOk={() => this.minimize()}
                    onCancel={() => this.minimize()}
                    footer={null}
                >
                    <div className="epar_pdf_full_screen_document_wrapper">
                        <PDFViewer docContentUrl={this.props.docContentUrl} fileName={this.props.fileName} isPDFViewMaximized={true} ></PDFViewer>
                    </div>
                </Modal>
                <Card>
                    <div className="epar_seperator pure-g" />
                    {this.renderControllers(pageNumber, numPages)}
                    <PDFReader 
                        url={this.props.docContentUrl ? this.props.docContentUrl : undefined}
                        data={this.props.docContent ? this.props.docContent : undefined}
                        page={pageNumber}
                        onDocumentComplete={this.onDocumentLoadSuccess} />
                    <div className="pure-g">
                        <div className="pure-u-2-5">
                            <Button type="primary" disabled={pageNumber === 1} onClick={() => this.prev()} >
                                <Icon type="left" /> Previous
                        </Button>
                        </div>
                        <div className="pure-u-1-5" style={{ textAlign: 'center', paddingLeft: '18px', paddingTop: '5px' }}>
                            <Tag closable={false}>{pageNumber} / {numPages}</Tag>
                        </div>
                        <div className="pure-u-2-5">
                            <Button className="epar_pull_right" type="primary" disabled={pageNumber === numPages} onClick={() => this.next()}>
                                Next <Icon type="right" />
                            </Button>
                        </div>
                    </div>
                </Card >
            </div>
        );
    }
}

export default PDFViewer;