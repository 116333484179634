import React, { Component } from 'react';
import { Form, Modal, Select, Input, DatePicker, Upload, Button, Icon, message } from 'antd';
import { connect } from 'react-redux';
import { DOCUMENT_CATEGORIES } from '../Constants';
import { addDocument, addUserDocument, addInitalUserDocumentVersion, addInitialDocumentVersion } from '../actions/documentActions';
import moment from 'moment';
import _ from 'lodash';
const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const validState = {
    status: 'success',
    message: null
}

const invalidUploadState = {
    status: 'error',
    message: 'Please upload a PDF'
}

const expiryDateHelper = "Expiry date is when the document must be renewed or revised";
const expiryDateInvalidHelper = "Please select an expiry date";

class NewDocumentModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            uploadState: validState,
            expiryDateHelper: expiryDateHelper
        }
    }

    resetFields = () => {
        this.setState({
            fileList: [],
            uploadState: validState,
            expiryDateHelper: expiryDateHelper
        });
    }

    handleCancel = () => {
        this.resetFields();
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleOk = () => {
        const { form } = this.props;
        const { fileList } = this.state;
        this.setState({ expiryDateHelper: expiryDateHelper });
        this.setState({ uploadState: validState });
        form.validateFields(async(err, values) => {

            if (!values.expiryDate) {
                this.setState({ expiryDateHelper: expiryDateInvalidHelper })
            }

            if (fileList.length === 0) {
                this.setState({ uploadState: invalidUploadState });
                return;
            }

            if (err) {
                return;
            }
            message.loading('Uploading document',0)
            const data = {
                title: values.documentTitle,
                category: values.category,
                expiryDate: values.expiryDate.format('DD/MM/YYYY')
            }
            if(this.props.isUserDoc){
                const docId = await addUserDocument(this.props.userId, data, this.state.fileList[0]);
                await addInitalUserDocumentVersion(docId, data.expiryDate);
            }else{
                const docId = await addDocument(this.props.contractorId, data, this.state.fileList[0]);
                await addInitialDocumentVersion(docId, data.expiryDate);
            }
            message.destroy();
            message.success('Uploaded successfully');

            form.resetFields();
            this.resetFields();
            this.props.onSave();
        });
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        const pdfUploadSettings = {
            action: '/upload',
            accept: '.pdf',
            multiple: false,
            beforeUpload: (file) => {
                this.setState(({ fileList }) => ({
                    fileList: [...fileList, file],
                }));
                return false;
            },
            onRemove: (file) => {
                let fileList = this.state.fileList;
                _.remove(fileList, (i) => i.name === file.name);
                this.setState({ fileList });
            },
            fileList: this.state.fileList
        }
        

        return (
            <Modal
                title={"Add New Document"}
                visible={this.props.visible}
                okText={"Save"}
                cancelText={"Cancel"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                destroyOnClose={true}
            >
                <Form layout='horizontal'>
                    <FormItem
                        {...formItemLayout}
                        label={"Document Title"}
                    >
                        {
                            getFieldDecorator('documentTitle', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please type document title'
                                    }
                                ]
                            })(
                                <Input maxLength={200} placeholder={"Enter title"} />
                            )
                        }
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={"Category"}
                    >
                        {
                            getFieldDecorator('category', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select document category'
                                    }
                                ]
                            })(
                                <Select placeholder={"Select category"} >
                                    {
                                        DOCUMENT_CATEGORIES.map(i => (
                                            <Option key={i.id} value={i.name}>{i.name}</Option>)
                                        )
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={"Expiry Date"}
                        help={this.state.expiryDateHelper}
                    >
                        {
                            getFieldDecorator('expiryDate', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select document expiry date'
                                    }
                                ]
                            })(
                                <DatePicker style={{ width: '100%' }} disabledDate={date=>date.isBefore(moment())}/>
                            )
                        }
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={"Upload PDF"}
                        validateStatus={this.state.uploadState.status}
                        help={this.state.uploadState.message}
                    >
                        {
                            getFieldDecorator('uploadPDF', {
                            })(
                                <Upload {...pdfUploadSettings}>
                                    <Button type="primary">
                                        <Icon type="upload" /> Click to Upload
                                    </Button>
                                </Upload>
                            )
                        }
                    </FormItem>

                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        contractorId: state.getIn(['loginUserState', 'contractorId']),
        userId: state.getIn(['loginUserState', 'userId'])
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NewDocumentModal));